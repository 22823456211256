.signup-user-confirm-wallet-wrap {
  height: 462.25px;
  width: 460px;
  max-width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: yellow; */
}
.signup-user-confirm-wallet-previous-step {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}
.signup-user-confirm-wallet-message{
  position: absolute;
  top: 100px;
}
.signup-user-confirm-wallet-title {
  width: 100%;
  height: 30px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  font-family: "britanica-heavy";
}
.signup-user-confirm-wallet-address-container {
  width: 221px;
  height: 70px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-top: 100px;
  display: flex;
  align-items: center;
  position: relative;
}
.signup-user-confirm-wallet-web3auth-logo {
  border-radius: 10px;
}
.signup-user-confirm-wallet-copy-logo {
  position: absolute;
  right: 13px;
  top: 35px;
  cursor: pointer;
}
.signup-user-confirm-wallet-copy-message-confirm {
  font-size: 14px;
  position: absolute;
  right: 0px;
  top: 18px;
  font-family: "britanica-black";
  color: #f6d463;
  /* background-color: red; */
  animation: slide-bottom-up 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.signup-user-confirm-wallet-copy-logo:active {
  transform: scale(0.85);
}
.signup-user-confirm-wallet-wallet-title-and-address {
  width: 121px;
  height: 43px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.signup-user-confirm-wallet-wallet-title {
  width: 100%;
  height: 19px;
  /* background-color: wheat; */
}
.signup-user-confirm-wallet-logo img {
  max-width: 50px;
  max-height: 50px;
  margin-left: 20px;
}
.signup-user-confirm-wallet-wallet-address {
  width: 100%;
  height: 24px;
  font-size: 20px;
  font-family: "britanica-heavy";
}
.signup-user-confirm-wallet-confirm-button {
  border: none;
  background: #f6d463;
  border-radius: 10px;
  width: 460px;
  height: 56px;
  margin-top: 100px;
  cursor: pointer;
  font-family: "britanica-heavy";
  font-size: 20px;
}
.signup-user-confirm-wallet-confirm-button:hover {
  background: #f7c520 !important;
  transition-duration: 300ms !important;
}
.signup-user-confirm-wallet-confirm-button:active {
  transform: scale(0.85);
}
.signup-user-confirm-wallet-change-wallet-button {
  border: none;
  border-radius: 10px;
  width: 460px;
  height: 56px;
  margin-top: 20px;
  cursor: pointer;
  font-family: "britanica-heavy";
  font-size: 20px;
  border: 1px solid #000000;
  border-radius: 10px;
  background: none;
}
.signup-user-confirm-wallet-change-wallet-button:hover {
  background: rgba(0, 0, 0, 0.144);
  transition-duration: 300ms;
}
.signup-user-confirm-wallet-change-wallet-button:active {
  transform: scale(0.85);
}
.signup-user-confirm-wallet-progress-bar-container {
  width: 100%;
  height: 10px;
  /* opacity: 0.2; */
  border-radius: 40px;
  margin-top: 20px;
  background: #85d87e67;
  z-index: 2 !important;
}
.signup-user-confirm-wallet-progress-bar {
  height: 100%;
  /* width: 25%; */
  border-radius: 40px;
  background: #85d87e;
  z-index: 10 !important;
  /* opacity: 1 !important; */
}
@keyframes slide-bottom-up {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
