.error-page-container {
  margin-top: 90px;
  /* background-color: red; */
  max-width: 1240px;
  width: 100% !important;
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-page-wrap {
  width: 90%;
  height: 90%;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-page-subwrap {
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}
.error-page-title {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 45px;
  font-family: "britanica-heavy";
  margin-top: 40px;
  /* background-color: firebrick; */
}
.error-page-description {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  height: 30%;
  font-weight: 700;
  font-family: "britanica-black";
  /* background-color: wheat; */
}
@media (max-width: 950px) {
    .error-page-subwrap{
        height: 40%;
    }
  .error-page-title {
    font-size: 35px;
  }
  .error-page-container img{
    width: 50px;
    height: 50px;
  }
  .error-page-description{
    font-size: 18px;
  }
}
