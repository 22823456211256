/* Animation Loading */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #f6d463;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
.signup-user-connect-wallet-wrap {
  max-height: 485.25px;
  height: 485.25px;
  width: 460px;
  position: relative;
  display: flex;
  flex-direction: column;
  /* background-color: yellow; */
}
.signup-user-connect-wallet-previous-step {
  position: absolute;
  top: 2.85px;
  left: 0px;
  cursor: pointer;
}
.signup-user-connect-wallet-title {
  font-size: 25px;
  font-family: "britanica-heavy";
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup-user-connect-wallet-list-container {
  width: 100%;
  height: 188px;
  /* background-color: wheat; */
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.signup-user-connect-wallet-list-wrap {
  width: 100%;
  height: 56px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border: 0.3px solid rgba(60, 64, 69, 0.4);
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
}
.signup-user-connect-wallet-list-wrap:active {
  transform: scale(0.85);
}
.signup-user-connect-wallet-list-wrap:hover {
  background: #f7c5205e;
  transition-duration: 500ms;
}
.signup-user-connect-wallet-list-logo img {
  margin-left: 20px;
  width: 30px;
  height: 30px;
}
.signup-user-connect-wallet-list-wallet-name {
  font-size: 20px;
  font-family: "britanica-heavy";
  margin-left: 10px;
}
.signup-user-connect-wallet-error-metamask {
  font-weight: 900;
  color: red;
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 245px;
  font-style: italic;
}
.signup-user-connect-wallet-next-button {
  background: none;
  border: none;
  width: 100%;
  height: 56px;
  font-family: "britanica-heavy";
  font-size: 20px;
  background: #f6d463;
  border-radius: 10px;
  margin-top: 40px;
  cursor: pointer;
}
.signup-user-connect-wallet-next-button:hover {
  background: #f7c520 !important;
  transition-duration: 300ms !important;
}
.signup-user-connect-wallet-next-button:active {
  transform: scale(0.85);
  transition-duration: 300ms !important;
}
.signup-user-connect-wallet-progress-bar-container {
  width: 100%;
  height: 10px;
  /* opacity: 0.2; */
  border-radius: 40px;
  margin-top: 20px;
  background: #85d87e67;
  z-index: 2 !important;
}
.signup-user-connect-wallet-progress-bar {
  height: 100%;
  /* width: 25%; */
  border-radius: 40px;
  background: #85d87e;
  z-index: 10 !important;
  /* opacity: 1 !important; */
}
.signup-user-separation-line-left {
  width: 43.4782609%;
  border-bottom: 0.3px solid #0000003b;
}
.signup-user-separation-or {
  opacity: 0.5;
  margin-left: 4.34782609%;
  margin-right: 4.34782609%;
}
.signup-user-separation-line-right {
  width: 43.4782609%;
  border-bottom: 0.3px solid #0000003b;
}
.signup-user-connect-wallet-web3auth-button {
  border: none;
  background: none;
  width: 100%;
  height: 56px;
  border: 0.3px solid rgba(60, 64, 69, 0.4);
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.signup-user-connect-wallet-web3auth-button:hover {
  background: #f7c5205e !important;
  transition-duration: 300ms !important;
}
.signup-user-connect-wallet-web3auth-button:active {
  transform: scale(0.75);
}
.signup-user-connect-wallet-web3auth-button-img-container img {
  width: 30px;
  height: 30px;
  border-radius: 10px;
}
.signup-user-connect-wallet-web3auth-button div {
  font-family: "britanica-heavy";
}
.signup-user-connect-wallet-button-title {
  font-size: 20px;
  margin-left: 10px;
}
.signup-user-connect-wallet-error-web3auth {
  font-weight: 900;
  color: red;
  font-size: 14px;
  text-align: center;
  position: absolute;
  bottom: 105px;
  font-style: italic;
  animation: slide-in 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes slide-in {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes copy-animation {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.copy-animation {
  animation: copy-animation 2s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
