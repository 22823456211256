.signup-user-container {
  width: 500px;
  min-height: 375px;
  height: 750px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 20.3424px rgba(0, 0, 0, 0.08);
  border-radius: 9.94299px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signup-user-error-google-message-container {
  width: 500px;
  /* min-height: 375px; */
  height: 250px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 20.3424px rgba(0, 0, 0, 0.08);
  border-radius: 9.94299px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  text-align: center;
  font-size: 18px;
  font-family: "britanica-black";
  padding: 20px;
}
.signup-user-error-google-message-container p {
  position: absolute;
  top: 55%;
}
.signup-user-error-google-message-error-logo {
  position: absolute;
  top: 15%;
  margin-bottom: 80px;
}
.signup-user-error-google-message-previous-step {
  position: absolute;
  top: 7%;
  left: 2.5%;
  margin-bottom: 80px;
  cursor: pointer;
}
/* =========================================================== */
/* ================  Conditionnal rendering ========================================= */
/* When ConfirmationCode Component */
.signup-user-confirmation-code-container {
  width: 500px !important;
  min-height: 375px;
  height: 483px !important;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 0px 20.3424px rgba(0, 0, 0, 0.08);
  border-radius: 9.94299px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/*  */

/* When Setup Profile Component */
.signup-user-setup-profile-container {
  width: 500px;
  height: 629px;
  min-height: 375px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 20.3424px rgba(0, 0, 0, 0.08);
  border-radius: 9.94299px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* When Connect Wallet Component */
.signup-user-connect-wallet-container {
  width: 500px;
  height: 534px;
  min-height: 375px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 20.3424px rgba(0, 0, 0, 0.08);
  border-radius: 9.94299px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* When Confirm Wallet Component */
.signup-user-confirm-wallet-container {
  width: 500px;
  height: 502.25px;
  min-height: 375px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 20.3424px rgba(0, 0, 0, 0.08);
  border-radius: 9.94299px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* When Validation Signup */
.signup-user-validation-signup-container {
  width: 500px;
  height: 418px;
  min-height: 375px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 20.3424px rgba(0, 0, 0, 0.08);
  border-radius: 9.94299px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* =========================================================== */
.signup-user-wrap-form {
  width: 460px;
  height: 705px;
  user-select: none;
  /* background-color: aqua; */
}
.signup-user-title {
  width: 460px;
  height: 38px;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  font-size: 35px;
  font-family: "britanica-heavy";
}
.signup-user-title-description {
  font-size: 16px;
  text-align: center;
  height: 38px;
  margin-top: 10px;
  /* background-color: wheat; */
}
.signup-user-mail-title {
  height: 17px;
  font-family: "britanica-heavy";
  font-size: 14px;
  margin-top: 15px;
  /* background-color: tomato; */
}
.signup-user-mail-input {
  height: 35px;
  width: 97.5%;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.281);
  /* opacity: 0.5; */
  caret-color: #f6d463;
  margin-top: 10px;
  padding-left: 10px;
}
.signup-user-phone-title,
.signup-user-password-title,
.signup-user-confirmation-password-title,
.signup-user-username-title {
  height: 17px;
  font-family: "britanica-heavy";
  font-size: 14px;
  /* background-color: tomato; */
  margin-top: 15px;
}
.signup-user-confirmation-password-title{
  margin-top: 20px !important;
}
.signup-user-password-input-container,
.signup-user-confirm-password-input-container {
  position: relative;
}
.signup-user-phone-input,
.signup-user-password-input,
.signup-user-confirmation-password-input,
.signup-user-username-input {
  height: 35px;
  width: 97.5%;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.281);
  /* opacity: 0.5; */
  caret-color: #f6d463;
  margin-top:8px;
  padding-left: 10px;
}
.PhoneInputInput {
  border: none;
  opacity: 0.5;
}
.PhoneInputInputOpacity {
  border: none;
  opacity: 0.5;
  opacity: 1 !important;
}
.PhoneInputInput:active,
.PhoneInputInput:focus {
  border: none;
  opacity: 1;
}
.PhoneInputInput:focus-visible {
  border: none;
  width: 105% !important;
  height: 35px;
  /* background-color: red; */
  border-left: none !important;
}
.password-error-message-signup,
.signup-user-error-mail,
.signup-user-error-phone,
.signup-user-error-username,
.signup-user-error-password,
.signup-user-error-fill-form {
  color: red;
  font-size: 10px;
  font-weight: 900;
}
.signup-user-error-username, .signup-user-error-password{
  position: absolute;
}
.signup-user-error-fill-form {
  text-align: center;
  margin-top: 5px;
}
.signup-user-input-display-button {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
}
.signup-user-create-account-button {
  outline: none;
  border: none;
  width: 100%;
  height: 56px;
  margin-top: 15px;
  background: #f6d463;
  border-radius: 10px;
  font-family: "britanica-heavy";
  font-size: 20px;
  cursor: pointer;
}
.signup-user-create-account-button:hover {
  background: #f7c520 !important;
  transition-duration: 300ms !important;
}
.signup-user-create-account-button:active {
  transform: scale(0.85);
}
.signup-page-confirmation-accept-cgu {
  font-style: italic;
  font-size: 13px;
  margin-top: 5px;
  text-align: center;
}
.signup-page-confirmation-accept-cgu a {
  /* background-color: red; */
  text-decoration: underline;
  color: blue;
}
.signup-user-separation-line-container {
  height: 17px;
  width: 100%;
  /* background-color: violet; */
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.signup-user-separation-line-left {
  width: 43.4782609%;
  border-bottom: 0.3px solid #0000003b;
}
.signup-user-separation-or {
  opacity: 0.5;
  margin-left: 4.34782609%;
  margin-right: 4.34782609%;
}
.signup-user-separation-line-right {
  width: 43.4782609%;
  border-bottom: 0.3px solid #0000003b;
}
.signup-user-google-signup {
  height: 56px;
  border: 0.3px solid rgba(60, 64, 69, 0.4);
  border-radius: 10px;
  margin-top: 10.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
  width: 100%;
}
.signup-user-google-signup:hover {
  background: #f6d46391 !important;
  transition-duration: 500ms !important;
}
.signup-user-google-signup:active {
  transform: scale(0.85);
  user-select: none;
}
.signup-user-google-signup img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.signup-user-google-signup-text {
  font-size: 20px;
  font-family: "britanica-heavy";
}
.signup-user-google-error-general-message {
  color: red;
  font-size: 14px;
  font-weight: 900;
  text-align: center;
}
.signup-user-already-an-account {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  margin-top: 7px;
  text-align: center;
position: absolute;
bottom: 20px;
right: 100px;
}
.signup-user-already-an-account > span {
  margin-right: 5px;
}
.signup-user-already-an-account button:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
/* .signup-user-already-an-account span {
  position: relative;
  color: #f6d463;
  font-weight: 900;
  margin-left: 7px;
  cursor: pointer;
}
.signup-user-already-an-account span:hover:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background: #f6d463;
} */
/* Animation Loading */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #f6d463;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* ================= */
@media (max-height: 830px) {
  .signup-user-container {
    width: 500px;
    min-height: 375px;
    height: 750px;
  }
  .signup-user-wrap-form {
    width: 460px;
    height: 725px;
  }
}
@media (max-height: 775px) {
  .signup-user-container {
    width: 500px;
    min-height: 375px;
    height: 700px;
  }
  .signup-user-wrap-form {
    width: 460px;
    height: 675px;
  }
  .signup-user-title {
    height: 28px;
    font-size: 25px;
  }
  .signup-user-title-description {
    font-size: 14px;
    height: 28px;
  }
  .signup-user-mail-title {
    height: 17px;
    margin-top: 10px;
  }
  .signup-user-phone-title,
  .signup-user-password-title,
  .signup-user-confirmation-password-title,
  .signup-user-username-title {
    height: 17px;
    font-family: "britanica-heavy";
    font-size: 14px;
    /* background-color: tomato; */
    margin-top: 10px;
  }
}
