.dashboard-stats-module {
  width: 100%;
  height: 100%;
  max-width: 202px;
  max-height: 97.43px;
  margin-left: 22.47px;
  /* background-color: aquamarine; */
}
.dashboard-stats-module-number-and-square {
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: blueviolet; */
}
.dashboard-stats-module-number {
  height: 100%;
  max-width: 144px;
  display: flex;
  align-items: center;
  font-size: 35px;
  font-family: "britanica-heavy";
}
.dashboard-stats-module-square {
  width: 44.83px;
  height: 44.83px;
  background: #f6d463;
  box-shadow: 0px 2px 10px rgba(124, 141, 181, 0.12);
  border-radius: 12.2254px;
}
.dashboard-stats-module-title-and-logo {
  width: 100%;
  height: 100%;
  max-width: 114.53px;
  max-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: wheat; */
}
.dashboard-stats-module-title {
  max-width: 100px;
  /* width: 100%; */
  /* background-color: tomato; */
}
.dashboard-stats-module-logo {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0.615385px solid #000000;
  cursor: pointer;
  font-size: 9.82px;
}
.dashboard-stats-module-progress-container {
  width: 100%;
  height: 100%;
  max-height: 21px;
  display: flex;
  align-items: center;
  margin-top: 12.23px;
  /* background-color: brown; */
}
.dashboard-stats-module-progress-arrow-logo img {
  width: 15px;
  height: 11.3px;
  padding-right: 5px;
}
.dashboard-stats-module-progress-number {
  padding-right: 3px;
}
.dashboard-stats-module-progress-percent {
  margin-left: 15px;
}
@media (max-width:1110px) {
  .dashboard-stats-module-number{
    font-size: 24px !important;
  }
  .dashboard-stats-module-title{
    font-size: 14px;
  }
}
@media (max-width:1000px) {
  .dashboard-stats-module-square{
    width: 34px;
    height: 34px;
  }
  .dashboard-stats-module-number{
    font-size: 22px !important;
  }
  .dashboard-stats-module-title{
    font-size: 13px;
  }
  .dashboard-stats-module-progress-number{
    font-size: 12px ;
  }
  .dashboard-stats-module-progress-percent{
    font-size: 12px;
  }
}
@media (max-width: 850px) {
  .dashboard-stats-module-number{
    font-size: 18px !important;
  }
  .dashboard-stats-module-title{
    font-size: 11px;
  }
}