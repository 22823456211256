.signup-user-validation-signup-wrap {
  max-height: 485px;
  height: 378px;
  width: 460px;
  position: relative;
  display: flex;
  flex-direction: column;
  /* background-color: yellow; */
}
.signup-user-validation-signup-previous-step {
  position: absolute;
  top: 2.85px;
  left: 0px;
  cursor: pointer;
}
.signup-user-validation-signup-greencross {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 53px;
}
.signup-user-validation-signup-greencross img {
  width: 60px;
  height: 60px;
}
.signup-user-validation-signup-title {
  font-size: 25px;
  font-family: "britanica-heavy";
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.signup-user-validation-signup-wrap button {
  background: #f6d463 !important;
  border-radius: 10px;
  height: 56px;
  border: none;
  font-size: 20px;
  font-family: "britanica-heavy";
  cursor: pointer;
  margin-top: 117px;
}
.signup-user-validation-signup-wrap button:active {
  transform: scale(0.85);
}

.signup-user-validation-signup-close-button:active {
  transform: scale(0.85);
}
.signup-user-validation-signup-progress-bar-container {
  width: 100%;
  height: 10px;
  /* opacity: 0.2; */
  border-radius: 40px;
  margin-top: 20px;
  background: #85d87e67;
  z-index: 2 !important;
}
.signup-user-validation-signup-progress-bar {
  height: 100%;
  /* width: 25%; */
  border-radius: 40px;
  background: #85d87e;
  z-index: 10 !important;
  /* opacity: 1 !important; */
}
