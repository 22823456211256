.dashboard-my-calendar-container{
    max-width: 1041.6px;
}
.dashboard-my-calendar-date-title{
    font-size: 22px;
    font-family: "britanica-heavy";
}
.dashboard-my-calendar-wrap{
    width: 100%;
    max-width: 1041.6px;
    display: flex;
    flex-wrap: wrap;
    /* background-color: red; */
}