/* 1245px */
.dashboard-page-container {
  width: 96%;
  max-width: 1395px;
  display: flex;
  justify-content: center;
  margin-top: 105px;
  /* background-color: red; */
}
.dashboard-page-wrap {
  width: 100%;
  max-width: 1245px;
  height: 100%;
  display: flex;
  justify-content: center;
  /* overflow: scroll; */
  /* background-color: aqua; */
}
.dashboard-page-my-collections-container {
  width: 100%;
  display: flex;
  /* background-color: red; */
  margin-left: 25px;
  /* background-color: tomato; */
}
.dashboard-page-stats-container {
  width: 100%;
  min-height: 687px;
  display: flex;
  justify-content: flex-end;
  margin-left: 25px;
  /* background-color: violet; */
}
.dashboard-page-my-calendar-container {
  width: 100%;
  /* max-width: 1080px; */
  /* background-color: bisque; */
  display: flex;
  margin-left: 21px;
}
@media (max-width: 1260px) {
  .dashboard-page-container {
    max-width: 1205px;
  }
}
@media (max-width: 911px) {
  .dashboard-page-my-collections-container {
    display: flex;
    /* justify-content: center ; */
  }
}
@media (max-height: 800px) {
  .dashboard-page-container{
    max-height: 650px !important;
  }
}
