.navbar-icon-container {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  /* padding: 8px; */
  position: relative;
}
.navbar-icon-container:hover {
  background-color: #00000015;
  transition-duration: 300ms !important;
  border-radius: 30px;
}
.navbar-icon {
  width: 20px;
  cursor: pointer;
}
.navbar-icon-notifications-red-round{
  background-color: red;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 20%;
  right: 25%;
}
