.dashboard-submenu-container {
  width: 100%;
  height: 100%;
  max-width: 137px;
  display: flex;
  justify-content: space-between;
  /* background-color: rgba(255, 0, 0, 0.439); */
}
.dashboard-submenu-stats-button,
.dashboard-submenu-my-collections-button,
.dashboard-submenu-my-calendar-button {
  width: 127px;
  height: 33px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  font-family: "britanica-heavy";
  cursor: pointer;
  margin-bottom: 10px;
}
.dashboard-submenu-container span {
  padding-left: 10px;
}
.dashboard-submenu-stats-button:hover,
.dashboard-submenu-my-collections-button:hover,
.dashboard-submenu-my-calendar-button:hover {
  background: #f6d463a6;
}
.dashboard-submenu-line-separation {
  height: 100%;
  width: 0px;
  border: 0.314412px solid rgba(0, 0, 0, 0.3);
}
