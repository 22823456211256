.button-component{
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0;
    margin: 0;
    box-sizing: content-box;
    user-select: none;
}
.button-component-create-post:hover {
    background-color: #f7c520 !important;
    transition-duration: 300ms !important;
}
.button-hover-props:hover{
    background-color: #f7c520 !important;
    transition-duration: 300ms !important;
}
.button-active-props:active{
transform: scale(0.8);
}
/* .button-component:hover{
    background-color: #f7c520 !important;
    transition-duration: 300ms !important;
} */