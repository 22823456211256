.signup-user-confirmation-code-wrap {
  width: 460px !important;
  height: 413px !important;
  position: relative;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signup-user-confirmation-code-previous-step {
  position: absolute;
  top: -12px;
  left: 0px;
  cursor: pointer;
  padding: 5px;
}
.signup-user-confirmation-code-previous-step:hover {
  background-color: #00000015;
  transition-duration: 300ms !important;
  border-radius: 30px;
  padding: 5px;
}
.signup-user-confirmation-code-previous-step:active {
  transform: scale(0.65);
  transition: 300ms;
}
.signup-user-confirmation-code-title {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "britanica-heavy";
  font-size: 25px;
  display: flex;
  justify-content: center;
  /* background-color: wheat; */
}
.signup-user-confirmation-code-description {
  width: 100%;
  height: 30px;
  text-align: center;
  margin-top: 20px;
  /* background-color: violet; */
}
.signup-user-confirmation-code-mail {
  /* width: 148px; */
  height: 39px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  /* background-color: teal; */
}
.signup-user-confirmation-code-input-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  /* background-color: seagreen; */
}
.signup-user-confirmation-code-input {
  width: 66.67px;
  height: 68px;
  border: 1px solid #000000;
  border-radius: 5px;
  text-align: center;
}
/* Faire disparaitre les fleches dans les inputs type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.signup-user-confirmation-code-message-resend {
  font-size: 14px;
  margin-top: 40px;
  font-style: italic;
  position: absolute;
  bottom: 120px;
}

.signup-user-confirmation-code-message-resend span {
  text-decoration: underline;
  cursor: pointer;
  color: #f6d463;
}
.signup-user-confirmation-code-message-resend span:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #f7c520 !important;
}
.signup-user-confirmation-code-message-interval-limit{
  color: red;
  font-weight: 900;
  font-size: 11px;
  position: absolute;
  bottom: 90px;
}
.signup-user-confirmation-code-message-error{
  color: red;
  font-weight: 900;
  font-size: 12px;
  position: absolute;
  bottom: 96px;
}
.signup-user-confirmation-code-message-resend-confirmation{
  font-style: italic;
  color: #85d87e;
  font-size: 14px;
  position: absolute;
  bottom: 0px;
  bottom: 105px;
  animation: slide-in 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275);
}
.signup-user-confirmation-code-animation-container{
left: 190px;
  position: absolute;
  bottom: 100px;
}
.signup-user-confirmation-code-animation-container .lds-ellipsis{
  width: 40px !important;
  height: 40px !important;
}
.signup-user-confirmation-code-next-button {
  width: 100%;
  height: 56px;
  background: #f6d463;
  border-radius: 10px;
  border: none;
  margin-top: 70px;
  font-size: 20px;
  font-family: "britanica-heavy";
  cursor: pointer;
}
.signup-user-confirmation-code-next-button:hover {
  background-color: #f7c520 !important;
  /* border-radius: 10px; */
  transition-duration: 300ms !important;
}
.signup-user-confirmation-code-next-button:active {
  transform: scale(0.85);
}
.signup-user-confirmation-code-progress-bar-container {
  width: 100%;
  height: 10px;
  /* opacity: 0.2; */
  border-radius: 40px;
  margin-top: 20px;
  background: #85d87e67;
  z-index: 2 !important;
}
.signup-user-confirmation-code-progress-bar {
  height: 100%;
  /* width: 25%; */
  border-radius: 40px;
  background: #85d87e;
  z-index: 10 !important;
  /* opacity: 1 !important; */
}
.forgot-password-popup-validation-message-loading {
  position: absolute;
  top: 25%;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #f6d463;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #f6d463;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@keyframes slide-in {
  0% {  
    transform: translateX(-50px);
  }
  100% { 
    transform: translateX(0);
  }
}