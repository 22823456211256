.App {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
/* button:active {
  transform: scale(0.45) !important;
} */
.error-mobile-waiting-page{
  display: none;
}
@media (max-width: 800px) {
  .error-mobile-waiting-page {
    height: 100vh !important;
    width: 100vw !important;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    text-align: center;
    line-height: 40px !important;
    font-family: "britanica-heavy";
  }
  .waiting-page-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .logo-sofan-waiting-page-container {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .explore-picture-and-text-waiting-page {
    height: 60%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    font-family: "britanica-heavy";
    font-size: 30px;
  }
  .explore-picture-waiting-page {
    height: 250px;
    width: 500px;
  }

  .socials-network-waiting-page {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .logo-socials-waiting-page {
    height: 20%;
    width: 60vw !important;
    display: flex;
    justify-content: space-evenly;
  }
  .svg-socials-waiting-page {
    height: 30px;
    width: 30px;
    fill: #f6d463;
    cursor: pointer;
  }
  .svg-socials-waiting-page:hover {
    fill: #f7c520;
  }

  .App {
    display: none !important;
  }
  .App {
    display: none !important;
  }
}
@media (max-width:670px) {
  .logo-socials-waiting-page{
    width: 60vw !important;
  }
}
@media (max-height: 700px) {
  .error-mobile-waiting-page {
    height: 100vh !important;
    width: 100vw !important;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    text-align: center;
    line-height: 40px !important;
    font-family: "britanica-heavy";
  }
  .waiting-page-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .logo-sofan-waiting-page-container {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .explore-picture-and-text-waiting-page {
    height: 60%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    font-family: "britanica-heavy";
    font-size: 30px;
  }
  .explore-picture-waiting-page {
    height: 250px;
    width: 500px;
  }

  .socials-network-waiting-page {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .logo-socials-waiting-page {
    height: 20%;
    width: 60vw !important;
    display: flex;
    justify-content: space-evenly;
  }
  .svg-socials-waiting-page {
    height: 30px;
    width: 30px;
    fill: #f6d463;
    cursor: pointer;
  }
  .svg-socials-waiting-page:hover {
    fill: #f7c520;
  }

  .App {
    display: none !important;
  }
}
